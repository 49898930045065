import React from 'react';
import './bento-frontend-developer.scss';

const CoverLetters = () => {

  return (
    <div className="coverletter">
      <p>
      <b>Favour Chidimma Nworah</b> <br/> 
      chidimmanworah12@gmail.com<br/> 
      10th January, 2025<br/> <br/> 

<b>Hiring Manager</b> <br/> 
Bpo - Tech  <br/> 

<b>Dear Hiring Manager,</b>

I am excited to apply for the <b>Front-End Developer (Contract)</b> position at Bpo - Tech. With over three years of hands-on experience in front-end development and a strong proficiency in React, HTML, CSS, and JavaScript, I am eager to contribute to your team’s success in creating robust and user-friendly tools.  

In my previous roles, I have demonstrated expertise in designing and developing responsive, visually appealing web interfaces while ensuring seamless functionality. Highlights of my experience include:  
<ul>
<li><b>React Expertise</b>: Developed reusable React components and optimized state management using libraries like Redux, resulting in modular and efficient applications. </li> 
<li><b>Responsive Design</b>: Designed cross-browser-compatible interfaces that deliver an intuitive user experience on both desktop and mobile devices.  </li> 
<li><b>Performance Optimization</b>: Debugged and improved front-end performance, reducing page load times by up to 40% for past projects. </li> 
<li><b>Version Control</b>: Collaborated with teams using Git to manage codebases and ensure smooth project workflows. </li> 
</ul>
Your emphasis on building high-quality, user-centric tools aligns perfectly with my commitment to creating seamless user experiences. My ability to work independently in remote environments and deliver results within deadlines makes me an ideal candidate for this role.  <br/><br/>

I am particularly drawn to the opportunity to contribute to a dynamic project with significant impact while collaborating with a passionate team. The flexible, remote working arrangement is another aspect of the role that resonates with my professional working style.  <br/><br/>

I am eager to bring my skills and dedication to Bpo - Tech and help build something extraordinary. I would welcome the opportunity to discuss how my experience and expertise align with your team’s goals.<br/><br/>  

Thank you for considering my application. I look forward to the possibility of contributing to your success.  <br/><br/>

<b>Best regards,</b> <br/><br/> 
Favour Chidimma Nworah 
      </p>
    </div>
  );
};

export default CoverLetters;
